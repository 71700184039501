import styled from 'styled-components';
import BackgroundLg from '../assets/social-media-callout-bg-lg.png';
import BackgroundMd from '../assets/social-media-callout-bg-md.png';
import BackgroundSm from '../assets/social-media-callout-bg-sm.png';
import BackgroundXs from '../assets/social-media-callout-bg-xs.png';
import { colors } from '../../../styles/atoms/colors';
import { font } from '../../../styles/atoms/typography';
import { atMinWidth } from '../../../styles/atoms/breakpoints';

export const Section = styled.section`
width: 100%;
height: auto;
padding: 48px 23.5px;
${atMinWidth.sm`
  padding: 48px 35px;
`}
${atMinWidth.md`
  padding: 72px 35px;
`}
${atMinWidth.lg`
  padding: 96px 35px;
`}
`;
export const OuterWrapper = styled.div`
width: 100%;
max-width: 1170px;
height: auto;
padding: 32px 0px;
margin: 0px auto;
background-image: url(${BackgroundXs});
background-size: cover;
background-repeat: no-repeat;
border-radius: 10px;
overflow: hidden;
${atMinWidth.xs`
  padding: 72px 0px;
  background-image: url(${BackgroundSm});
`}
${atMinWidth.md`
  background-image: url(${BackgroundMd});
`}
${atMinWidth.lg`
  background-image: url(${BackgroundLg});
`}
`;
export const InnerWrapper = styled.div`
  width: 100%;
  max-width: 704px;
  display: flex;
  flex-direction: column;
  text-align: center;
  gap: 32px;
  padding: 0px 32px;
  margin: 0px auto;
`;
export const Header = styled.div`
width: 100%;
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
gap: 24px;
.heading-kicker {
  ${font('overline', 'sm', '700')}
  color: ${colors.primary[700]};
}
.heading {
  ${font('display', 'md', '700')}
  color: ${colors.gray[900]};
  ${atMinWidth.lg`
    ${font('display', 'lg', '700')}
  `}
}
.subhead {
  ${font('text', 'md', '400')}
  color: ${colors.gray[600]};
  ${atMinWidth.sm`
    ${font('text', 'lg', '400')}
  `}
}
`;
export const Grid = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 13px;
  ${atMinWidth.xs`
  gap: 24px;
`}
  ${atMinWidth.lg`
  gap: 32px;
`}
`;
